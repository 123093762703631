// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./ny.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.garland {
  width: 100%;
  height: 90px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: repeat-x;
  background-size: contain;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -2.8em;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/customEvents/newYear/christmas.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yDAAA;EAEA,2BAAA;EACA,2BAAA;EACA,wBAAA;EAEA,oBAAA;EAEA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AAFF","sourcesContent":[".garland {\n  width: 100%;\n  height: 90px;\n  background-image: url(\"../../customEvents/newYear/ny.png\");\n\n  background-position: center;\n  background-repeat: repeat-x;\n  background-size: contain;\n\n  pointer-events: none;\n\n  position: absolute;\n  right: 0;\n  top: -2.8em;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
